import {useState} from "react";
import {IconButton, Tooltip} from "@mui/material";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from "prop-types";

const CopyToClipboardButton = (props) => {
    const {text, title} = props;
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
        navigator.clipboard.writeText(text);
        setTimeout(() => setOpen(false), 2000)
    };

    return (
        <>
            <Tooltip title={title}>
                <IconButton onClick={handleClick} color="primary">
                    <ContentCopyRoundedIcon fontSize="small"/>
                </IconButton>
            </Tooltip>
            {open && <CheckIcon fontSize="small"/>}
        </>
    );
};

CopyToClipboardButton.propTypes = {
    text: PropTypes.string,
    title: PropTypes.string,
}

export default CopyToClipboardButton;
