import React from "react";
import Stack from "@mui/material/Stack";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {toLondonDateTime} from "../../common/utils";
import Card from "@mui/material/Card";
import PropTypes from "prop-types";

const cardStyle = {
    p: 2,
    mb: 2,
    gap: 2,
    flex: 1,
    height: 'calc(100vh - 30rem)',
    overflow: "auto"
};

function ChatMessagesList(props) {
    const {messages, lastElementRef} = props;

    return (
        <Card sx={cardStyle} style={{display: "flex"}}>
            <Stack direction="column-reverse" overflow="auto" flex={1} gap={1.5}>
                {messages?.map((message, index) => (
                    <ListItem key={message.messageId}
                              ref={index === messages.length - 1 ? lastElementRef : null}
                        /*sx={isMyMessage ? myMessageStyle : style}*/>
                        <ListItemIcon>
                            <Avatar />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{mb: 0.5}}
                            secondaryTypographyProps={{ whiteSpace: 'pre-wrap' }}
                            primary={
                                <>
                                    <Typography variant="subtitle1" display="inline" marginRight={1}>
                                        {message.sender?.name}
                                    </Typography>
                                    {toLondonDateTime(message.createdTimestamp)}
                                </>
                            }
                            secondary={message.content}
                        />
                    </ListItem>
                ))}

            </Stack>
        </Card>
    );
}

ChatMessagesList.propTypes = {
    messages: PropTypes.array,
    lastElementRef: PropTypes.object,
}

export default ChatMessagesList;
