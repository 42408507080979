import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

function ChatInputMessage(props) {
    const {handleSendMessage, setNewMessage, newMessage} = props;
    return (
        <TextField
            value={newMessage}
            onKeyDown={({ key, shiftKey }) =>
                !shiftKey && key === 'Enter' && handleSendMessage()
            }
            onChange={({ target }) => setNewMessage(target.value)}
            multiline
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <InputAdornment position="end">
                            <IconButton
                                data-testid="masked-input-btn"
                                disabled={!newMessage}
                                onClick={handleSendMessage}
                                size="small"
                            >
                                <SendIcon />
                            </IconButton>
                        </InputAdornment>
                    </InputAdornment>
                ),
            }}
        />
    );
}

ChatInputMessage.propTypes = {
    handleSendMessage: PropTypes.func,
    setNewMessage: PropTypes.func,
    newMessage: PropTypes.string
}

export default ChatInputMessage;