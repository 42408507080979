import {
    Datagrid,
    FunctionField,
    List,
    ListContextProvider, ReferenceField,
    Show,
    ShowButton,
    SimpleShowLayout,
    TextField,
    TextInput,
    useDataProvider,
    useList,
    useNotify, useRecordContext,
    useShowController,
    useTheme
} from "react-admin";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {getHumanReadableAmount, onError} from "../../common/utils";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {PartyField} from "../controls/PartyField";
import {PARTY_TYPE} from "../../common/constants";
import Resources from "../../Resources";
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import {Tooltip} from "@mui/material";
import CopyToClipboardButton from "../controls/CopyToClipboardButton";
import ChatMessagesList from "./ChatMessagesList";
import {useIntersectionObserver} from "../../common/hooks";

export const ChatObjectType = {
    OPPORTUNITY: {
        code: 'OPPORTUNITY',
        label: 'Funding opportunity',
        reference: Resources.OPPORTUNITIES.name,
        showLink: "/loanApplications/${refId}/show/4?id=${id}"
    },
    LOAN_APPLICATION: {
        code: 'LOAN_APPLICATION',
        label: 'Loan application',
        reference: Resources.LOAN_APPLICATIONS.name,
        showLink: "/loanApplications/${id}/show",
        getManyResource: "loanApplications/findBriefByIDs"
    },
    CREDITOR_APPLICATION: {
        code: 'CREDITOR_APPLICATION',
        label: 'Debt Investor application',
        reference: Resources.CREDITOR_APPLICATIONS.name,
        showLink: "/creditorApplications/${id}/show"
    },
}

const listFilters = [
    <TextInput source="arn" label="Channel ARN" resettable alwaysOn sx={{width: "800px"}}/>,
];

const LinkToObject = (props) => {
    const {label} = props
    const record = useRecordContext()
    if (!record && !record.metadata) return null;
    const metadata = record?.metadata

    function getDisplayText(record) {
        if (metadata.type === ChatObjectType.LOAN_APPLICATION.code) {
            return getHumanReadableAmount(record.loanAmount, record.loanCurrency)
                + ' (' + record.borrowerCompanyFullName + ')'
                + (record.isDeleted?' (deleted)':'');
        } else {
            return `${record.displayName}${record.isDeleted?' (deleted)':''}`;
        }
    }

    const defineReference = () => {
        return ChatObjectType[metadata?.type]?.reference;
    }
    const defineLink = (record, reference) => {
        let auditObjectTypeElement = ChatObjectType[metadata?.type];
        let showLink = auditObjectTypeElement?.showLink;
        if (showLink) {
            showLink = showLink.replace("${refId}", record.refId)
            showLink = showLink.replace("${id}", record.id)
        }
        return record.isDeleted ? '' : (showLink ? showLink : reference)
    }
    const defineMeta = () => {
        let getManyResource = ChatObjectType[metadata?.type]?.getManyResource;
        if (getManyResource) {
            return {getManyResource}
        }
        return {}
    }

    const meta = defineMeta()
    const reference = defineReference()

    return (
        <ReferenceField source="metadata.externalId" reference={reference}
                        link={defineLink} label={label}
                        queryOptions={{meta}}>
            <>
                <FunctionField render={getDisplayText}/>
            </>
        </ReferenceField>
    )
}

export const ChatChannelsList = (props) => {
    const tokens = useRef([''])

    function convertResponse(response, page) {
        // console.log("convertResponse", response, page, tokens.current.length)
        if (tokens.current.length < page+2) {
            tokens.current.length = page+2
        }
        tokens.current[page+1] = response.data?.data?.nextToken
        // console.log("convertResponse_after", tokens.current.length, tokens.current)

        const arr = response.data?.data?.channels;
        arr.forEach(e => {
            if (e.metadata) {
                e.metadata = JSON.parse(e.metadata)
            }
        })
        return {
            data: arr,
            pageInfo: response.data?.data?.pageInfo
        }
    }

    function errorHandler(error) {
        tokens.current = ['']
        // console.log("error", error)
        if (error.response.status === 463) {
            return {data: [], pageInfo: {hasPreviousPage: false, hasNextPage: true}}
        }
    }

    function setTokenToQuery(page) {
        // console.log("setTokenToQuery", tokens.current.length, page, tokens.current)
        return tokens.current.length >= page+1 && tokens.current[page] ? `&nextToken=${tokens.current[page]}` : ''
    }

    return (
        <>
            <List sort={{field: "name", order: "ASC"}} filters={listFilters} perPage={50}
                  queryOptions={{meta: {transform: convertResponse, updateQueryParams: setTokenToQuery, errorHandler}}}>
                <Datagrid rowClick={false} bulkActionButtons={false}>
                    <TextField source="name"/>
                    <TextField source="channelArn"/>
                    <LinkToObject label="Object"/>
                    <ShowButton/>
                </Datagrid>
            </List>
        </>
    )
}

const Members = (props) => {
    const {record} = props
    const [data, setData] = useState([])
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const loadData = () => {
        const channelArn = record.id.replaceAll(/\//g, '*');
        dataProvider.doAction(`${Resources.CHAT.name}/${Resources.CHAT.actions.CHANNELS.name}/${channelArn}/${Resources.CHAT.actions.CHANNELS.subrequests.MEMBERS.name}`, {
            method: 'GET',
        }).then(({data}) => {
            // console.log("data", data);
            setData(data.data);
        }).catch((err) => {
            onError(err, notify)
        });
    }

    useEffect(() => {
            loadData()
        }, [record]);

    let membersContext = useList({data});
    return (
        <ListContextProvider value={membersContext}>
            <Datagrid bulkActionButtons={false}>
                <PartyField source="id" partyType={PARTY_TYPE.PERSON} label="Person"/>
                <TextField source="login"/>
            </Datagrid>
        </ListContextProvider>
    )
}

export const ChatChannelShowPage = (props) => {
    const [theme] = useTheme();
    const [readonly, setReadonly] = useState(false)
    const {record} = useShowController();
    const [messages, setMessages] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();

    useEffect(() => {
        setReadonly(!!record?.tags?.find((element) => element.key === 'readonly' && element.value === 'true'))
    }, [record])

    const refreshMessages = useCallback((append) => {
        if(!record || isLoading || (append && !nextToken)) {
            return;
        }

        setLoading(true);
        dataProvider['doAction'](Resources.CHAT.name, {
            action: Resources.CHAT.actions.MESSAGES.name,
            queryStr: `channelArn=${record.id}` + (append && nextToken ? `&nextToken=${nextToken}` : ''),
            method: 'GET'
        }).then(({data}) => {
            if(append) {
                setMessages([...messages, ...data.data.messages])
            } else {
                setMessages(data.data.messages);
            }
            setNextToken(data.data.nextToken)
        }).catch((error) => {
            onError(error, notify);
        }).finally(() => {
            setLoading(false);
        });
    }, [isLoading, notify, nextToken, record]);

    useEffect(() => {
        refreshMessages(false);
    }, [record]);

    const lastElementRef = useIntersectionObserver(() => {
        refreshMessages(true);
    });

    if (!record) return null;

    return (
        <Show>
            <SimpleShowLayout record={record} className="show-layout-plain">
                <Grid container spacing={1}>
                    <Grid item xs={6} className={`grid-fs grid-fs-${theme}`} sx={{display:"flex", flexDirection:"column"}}>
                        <Typography className={`grid-label grid-label-${theme}`}>Basic Info</Typography>
                        <Grid container spacing={1} className="form-section">
                            <Grid item xs={1}>
                                <Typography className={`form-field-label form-field-label-${theme}`}>Name:</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <TextField source="name"/>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography className={`form-field-label form-field-label-${theme}`}>ARN:</Typography>
                                <CopyToClipboardButton text={record?.channelArn} title="Copy Channel ARN to clipboard"/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextField source="channelArn"/>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography className={`form-field-label form-field-label-${theme}`}>Type:</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <FunctionField render={(record)=>{
                                    if (record.metadata) {
                                        const m = JSON.parse(record.metadata)
                                        return ChatObjectType[m.type]?.label || m.type
                                    }
                                    return "-"
                                }}/>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography className={`form-field-label form-field-label-${theme}`}>Ext. ID:</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <FunctionField render={(record)=>{
                                    if (record.metadata) {
                                        const m = JSON.parse(record.metadata)
                                        return m.externalId
                                    }
                                    return "-"
                                }}/>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography className={`form-field-label form-field-label-${theme}`}>Tags:</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <ul className="ul-clear">
                                {record.tags?.map(t=> {
                                    return (<li key={t.key}>{`${t.key}: ${t.value}`}</li>)
                                })}
                                </ul>
                            </Grid>
                            <Grid item xs={1}>
                                {readonly && <Tooltip title="Channel is readonly"><CommentsDisabledIcon/></Tooltip>}
                            </Grid>
                        </Grid>
                        <Typography className={`grid-label grid-label-${theme}`}>Members</Typography>
                        <Grid container spacing={1} className="form-section" sx={{flex:1}}>
                            <Grid item xs={12}>
                                <Members record={record}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} className={`grid-fs grid-fs-${theme}`}>
                        <Typography className={`grid-label grid-label-${theme}`}>Messages</Typography>
                        <ChatMessagesList messages={messages} lastElementRef={lastElementRef}/>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    )
}